/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { motion } from 'framer-motion'
import { graphql } from 'gatsby'
import { fade } from '@/utils/transitions'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import * as T from '@/types'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'
import { Hero } from '@/components/Hero'

function Licensing({ 
    pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() => cleanProps(blocks, media))
	const hero = data.find(block => block.type === 'acf/hero')

    const remainingBlocks = data.filter(block => block.type !== 'acf/hero')

    const seoImage = featured_media || hero.hero_background_image

	return (
		<motion.div
			variants={fade}
			initial="initial"
			animate="enter"
			exit="exit"
		>
			<SEO {...yoast} image={seoImage} />
			<div>
				<Hero {...hero} />
			</div>
			<h3 className="mt-6 leading-tight text-h3-fluid sm:text-h2-fluid text-primary wrapper font-museoSans">Choose the licensing plan that’s right for you</h3>
			<Blocks 
				licensing 
				data={remainingBlocks} 
				media={media} 
				currentPage={wordpress_id} 
			/>
		</motion.div>
	)
}

Licensing.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired
	})
}

export const query = graphql`
	query licensingPageQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}

	}
`

export default Licensing
